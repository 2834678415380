import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLoginUserMutation } from "../../../graphql/generated/graphql";
import { useAuth } from "../../../context/AuthProvider";
import { setCookie } from "../../../utils/storage";
import Button from "../../../components/Button";

interface LoginValues {
  email: string;
  password: string;
}

const initialValues: LoginValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string().required("Required"),
});

const LoginForm: React.FC = () => {
  const [loginUser, { data, error, loading }] = useLoginUserMutation();
  const { setToken, setUserId } = useAuth();
  const handleSubmit = async (values: LoginValues) => {
    try {
      await loginUser({
        variables: {
          email: values.email,
          password: values.password,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (data && data.loginUser.token) {
      setCookie("jwtToken", data.loginUser.token);
      setCookie("userId", data.loginUser.user.id);
      setUserId(data.loginUser.user.id);
      setToken(data.loginUser.token);
    }
  }, [data?.loginUser.token, data, setToken, setUserId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (data && data.loginUser && data.loginUser.user)
    return <p>Welcome back, {data.loginUser.user.firstName}</p>;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2">
              Email
            </label>
            <Field
              id="email"
              name="email"
              type="email"
              className="border border-gray-300 p-2 w-full"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-600"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block mb-2">
              Password
            </label>
            <Field
              id="password"
              name="password"
              type="password"
              className="border border-gray-300 p-2 w-full"
            />
            <ErrorMessage
              name="password"
              component="div"
              className="text-red-600"
            />
          </div>
          <Button type="submit" variant="primary">
            Login
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
