import { getCookie } from "../utils/storage";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";

// Authentication link that attaches the JWT token to each request's headers
const authLink = setContext((_, { headers }) => {
  const token = getCookie("jwtToken");
  return {
    headers: {
      ...headers,
      authorization: token ? `jwt ${token}` : "",
    },
  };
});

// HTTP link for handling queries and mutations
const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL, // Your GraphQL API endpoint
});

// Apollo Client setup
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink), // Combine auth link and HTTP link
  cache: new InMemoryCache(),
});

export default apolloClient;
