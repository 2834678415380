import { Link } from "react-router-dom";
import Button from "./Button";
import { deleteCookie } from "../utils/storage";
import { useAuth } from "../context/AuthProvider";

const NavBar: React.FC = () => {
  const { setToken, setUserId } = useAuth();
  const handleLogout = () => {
    deleteCookie("userId");
    deleteCookie("jwtToken");
    setToken(undefined);
    setUserId(undefined);
  };
  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="container gap-4 flex items-center">
          <Link to="/" className="text-white font-bold text-xl">
            Home
          </Link>

          <Link to="/thrivy" className="text-white font-bold text-xl">
            Thrivy
          </Link>
        </div>
        <div>
          <Button
            className="text-white font-bold text-xl"
            handleClick={handleLogout}
            variant={"primary"}
          >
            Logout
          </Button>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
