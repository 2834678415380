import clsx from "clsx";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

export interface ButtonProps {
  handleClick?: () => void;
  variant: "primary" | "danger" | "warning" | "info" | "text" | "square";
  className?: string;
  type?: "button" | "submit" | "reset";
  children?: ReactNode;
  Icon?: ReactNode;
  href?: string;
}

const Button: React.FC<ButtonProps> = ({
  handleClick,
  type,
  variant,
  className,
  children,
  Icon,
  href,
}) => {
  const buttonStyle = clsx(
    "my-2 text-lg font-bold",
    variant === "text" && "text-black py-2 px-2 mx-2 sm:px-4 sm:mx-4  rounded",
    variant === "primary" && "bg-primary text-black py-2 px-4 rounded",
    variant === "danger" && "bg-red-500 text-black py-2 px-4 rounded",
    variant === "info" && "text-black py-2 px-4 rounded",
    variant === "square" &&
      "text-black py-2 px-4 mx-4 rounded-xl bg-primary w-32 h-32"
  );

  if (href) {
    return (
      <Link
        replace
        className={clsx(className, buttonStyle)}
        onClick={handleClick}
        to={`${window.location.origin}${href}`}
      >
        <div className="flex flex-col text-center items-center">
          {Icon && <div className="justify-center self-center">{Icon}</div>}
          {children}
        </div>
      </Link>
    );
  }

  return (
    <button
      onClick={handleClick}
      type={type}
      className={clsx(className, buttonStyle)}
    >
      <div className="flex flex-col">
        {Icon && <div className="justify-center self-center">{Icon}</div>}
        {children}
      </div>
    </button>
  );
};

export default Button;
