import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import { getCookie } from "../utils/storage";

interface ProtectedRouteProps {
  children: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const location = useLocation();

  const { setToken, token, setUserId } = useAuth();

  useEffect(() => {
    if (getCookie("jwtToken") && !token) {
      setToken(getCookie("jwtToken"));
      setUserId(getCookie("userId"));
    }
  }, [setToken, token, setUserId]);

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
