import { ChangeEvent, FC, useEffect, useRef } from "react";
import {
  TopicType,
  useGetAllTopicsQuery,
  useGetSignedUrlMutation,
  useUpdateTopicMutation,
} from "../../graphql/generated/graphql";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";

interface EditableTopic extends TopicType {
  isEditing: boolean;
}

const Topics: FC = () => {
  const { data: topicsData, refetch } = useGetAllTopicsQuery();
  const [topics, setTopics] = useState<EditableTopic[]>([]);
  const [updateTopic] = useUpdateTopicMutation();
  const [getSignedUrl] = useGetSignedUrlMutation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleEditToggle = (id: string) => {
    const updatedTopics = topics.map((topic) => {
      if (topic.id === id) {
        if (!topic.isEditing) {
          return { ...topic, isEditing: true };
        } else {
          updateTopic({ variables: { topicId: id, ...topic } });
          refetch();
          return { ...topic, isEditing: false };
        }
      }
      return topic;
    });
    setTopics(updatedTopics);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string,
    key: string
  ) => {
    const value = event.target.value;
    const updatedTopics = topics.map((topic) => {
      if (topic.id === id) {
        return { ...topic, [key]: value };
      }
      return topic;
    });
    setTopics(updatedTopics);
  };

  const handleImageClick = (id: string) => {
    fileInputRef.current?.click();
    fileInputRef.current?.addEventListener("change", (event) => {
      if (fileInputRef.current?.files?.length) {
        const file = fileInputRef.current.files[0];

        uploadImage(file, id);
      }
    });
  };

  const uploadImage = async (file: File, id: string) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const updatedTopics = topics.map((topic) => {
        if (topic.id === id) {
          return { ...topic, thumbnailUrl: reader.result as string };
        }
        return topic;
      });
      setTopics(updatedTopics);
    };
    try {
      const uuid = uuidv4();
      const thumbnailFilename = `thumbnail_${uuid}.jpg`;
      const { data } = await getSignedUrl({
        variables: {
          photoFileName: thumbnailFilename,
        },
      });

      if (!data?.getSignedUrl?.photoUrl) {
        throw new Error("Error getting signed URLs");
      }

      const { photoUrl } = data.getSignedUrl;

      try {
        await Promise.all([
          fetch(photoUrl, {
            method: "PUT",
            body: file,
            headers: {
              "Content-Type": "image/jpeg",
            },
          }),
        ]);
        const uploadedThumbnailUrl = `https://ilonaphotos.s3.eu-west-2.amazonaws.com/${thumbnailFilename}`;

        await updateTopic({
          variables: {
            topicId: id,
            thumbnailUrl: uploadedThumbnailUrl,
          },
        });
        refetch();
        alert("Topic created successfully!");
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  useEffect(() => {
    if (topicsData) {
      const topicsWithEdit = topicsData.getAllTopics.map((topic) => ({
        ...topic,
        isEditing: false,
      }));
      setTopics(topicsWithEdit as EditableTopic[]);
    }
  }, [topicsData]);

  return (
    <div className="max-w-7xl mx-auto p-4 grid grid-cols-3 gap-4">
      {topics.map((topic) => (
        <div key={topic.id} className="border p-3 rounded shadow">
          <input
            type="text"
            value={topic.title}
            onChange={(e) => handleChange(e, topic.id, "title")}
            className="w-full mb-2 p-2 border rounded"
            disabled={!topic.isEditing}
          />
          <textarea
            value={topic.description}
            onChange={(e) => handleChange(e, topic.id, "description")}
            className="w-full h-24 p-2 border rounded mb-2"
            disabled={!topic.isEditing}
          />
          <img
            src={topic.thumbnailUrl || "default-thumbnail.png"}
            alt="Thumbnail"
            className="w-full max-h-32 object-cover rounded mb-2 cursor-pointer"
            onClick={() => handleImageClick(topic.id)}
          />
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            accept="image/*"
          />
          <button
            onClick={() => handleEditToggle(topic.id)}
            className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 float-right"
          >
            {topic.isEditing ? "Save" : "Edit"}
          </button>
        </div>
      ))}
    </div>
  );
};

export default Topics;
