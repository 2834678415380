import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

export enum AgeRange {
  Range18_24 = 'Range18_24',
  Range25_34 = 'Range25_34',
  Range35_44 = 'Range35_44',
  Range45_54 = 'Range45_54',
  Range55_64 = 'Range55_64',
  Range65Plus = 'Range65Plus'
}

export type AllTopicsType = {
  __typename?: 'AllTopicsType';
  description: Scalars['String'];
  id: Scalars['ID'];
  thumbnailUrl: Scalars['String'];
  title: Scalars['String'];
};

export enum ContentPreferences {
  AngerManagement = 'AngerManagement',
  Anxiety = 'Anxiety',
  BodyPositivity = 'BodyPositivity',
  HealthAnxiety = 'HealthAnxiety',
  HealthyEating = 'HealthyEating',
  PainManagement = 'PainManagement',
  Professional = 'Professional',
  SelfConfidence = 'SelfConfidence',
  SelfLimitingBeliefs = 'SelfLimitingBeliefs',
  SelfLove = 'SelfLove',
  Sleep = 'Sleep',
  WeightLoss = 'WeightLoss'
}

export type ContentType = {
  __typename?: 'ContentType';
  applicableAges?: Maybe<Array<Scalars['String']>>;
  applicableGenders?: Maybe<Array<Scalars['String']>>;
  applicablePreferences?: Maybe<Array<Scalars['String']>>;
  description: Scalars['String'];
  id: Scalars['ID'];
  thumbnailUrl: Scalars['String'];
  title: Scalars['String'];
  topicId?: Maybe<Scalars['String']>;
  topicName?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type CreateTextLessonPageArgs = {
  content: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
};

export enum Gender {
  Female = 'Female',
  Male = 'Male',
  Other = 'Other'
}

export enum LenghtOfVideos {
  Long = 'Long',
  Medium = 'Medium',
  NoPreference = 'NoPreference',
  Short = 'Short'
}

export type Mutation = {
  __typename?: 'Mutation';
  createTextLesson: SuccessPayload;
  createTopic: SuccessPayload;
  createUser: UserWithTokenType;
  deleteUser: SuccessPayload;
  generateContent: SuccessPayload;
  getSignedUrl: SignedUrl;
  loginUser: UserWithTokenType;
  markContentAsFavorite: SuccessPayload;
  updateContent: SuccessPayload;
  updateDeviceToken: SuccessPayload;
  updateTopic: SuccessPayload;
  updateUser: UserOutput;
  updateUserContent: SuccessPayload;
  uploadContent: SuccessPayload;
  uploadUserPhoto: Scalars['Boolean'];
};


export type MutationCreateTextLessonArgs = {
  duration: Scalars['Float'];
  pages: Array<CreateTextLessonPageArgs>;
  thumbnailUrl: Scalars['String'];
  title: Scalars['String'];
};


export type MutationCreateTopicArgs = {
  description: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  title: Scalars['String'];
};


export type MutationCreateUserArgs = {
  appleUserId?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteUserArgs = {
  userId: Scalars['String'];
};


export type MutationGetSignedUrlArgs = {
  audioFileName?: InputMaybe<Scalars['String']>;
  photoFileName?: InputMaybe<Scalars['String']>;
};


export type MutationLoginUserArgs = {
  appleUserId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};


export type MutationMarkContentAsFavoriteArgs = {
  contentId: Scalars['String'];
};


export type MutationUpdateContentArgs = {
  contentId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDeviceTokenArgs = {
  deviceToken: Scalars['String'];
};


export type MutationUpdateTopicArgs = {
  description?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  topicId: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UserInput;
  userId: Scalars['String'];
};


export type MutationUpdateUserContentArgs = {
  duration: Scalars['Float'];
  progressInSeconds: Scalars['Float'];
  userContentId: Scalars['String'];
};


export type MutationUploadContentArgs = {
  applicableAges?: InputMaybe<Array<AgeRange>>;
  applicableGender?: InputMaybe<Array<Gender>>;
  applicablePreferences?: InputMaybe<Array<ContentPreferences>>;
  audioUrl: Scalars['String'];
  description: Scalars['String'];
  mediaType: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  title: Scalars['String'];
  topicName?: InputMaybe<Scalars['String']>;
};


export type MutationUploadUserPhotoArgs = {
  file: Scalars['Upload'];
  userId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  currentUser: UserOutput;
  getAllContent: Array<ContentType>;
  getAllTopics: Array<AllTopicsType>;
  getContent: ContentType;
  getTopics: TopicsType;
  getUser: UserOutput;
  getUserContent: UserContentItems;
  textLesson: TextLessonType;
  textLessons: TextLessons;
};


export type QueryGetContentArgs = {
  contentId: Scalars['String'];
};


export type QueryGetUserArgs = {
  userId: Scalars['String'];
};


export type QueryGetUserContentArgs = {
  topicId: Scalars['String'];
};


export type QueryTextLessonArgs = {
  id: Scalars['String'];
};

export enum Role {
  Admin = 'ADMIN',
  Client = 'CLIENT',
  Specialist = 'SPECIALIST'
}

export type SignedUrl = {
  __typename?: 'SignedUrl';
  audioUrl?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
};

export type SuccessPayload = {
  __typename?: 'SuccessPayload';
  success: Scalars['Boolean'];
};

export type TextLessonPageType = {
  __typename?: 'TextLessonPageType';
  content: Scalars['String'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  textLessonId: Scalars['ID'];
};

export type TextLessonType = {
  __typename?: 'TextLessonType';
  duration: Scalars['Float'];
  id: Scalars['ID'];
  pages?: Maybe<Array<TextLessonPageType>>;
  thumbnailUrl: Scalars['String'];
  title: Scalars['String'];
};

export type TextLessons = {
  __typename?: 'TextLessons';
  textLessons: Array<TextLessonType>;
};

export enum ToneOfVoice {
  Calming = 'Calming',
  Encouraging = 'Encouraging',
  Energising = 'Energising',
  NoPreference = 'NoPreference'
}

export enum TopicPurpose {
  Practice = 'Practice',
  Theory = 'Theory'
}

export type TopicType = {
  __typename?: 'TopicType';
  contents: Array<UserContentItem>;
  description: Scalars['String'];
  id: Scalars['ID'];
  purpose: TopicPurpose;
  thumbnailUrl: Scalars['String'];
  title: Scalars['String'];
};

export type TopicsType = {
  __typename?: 'TopicsType';
  topics: Array<TopicType>;
};

export type UserContentItem = {
  __typename?: 'UserContentItem';
  description: Scalars['String'];
  duration: Scalars['Float'];
  id: Scalars['ID'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  isRecommended?: Maybe<Scalars['Boolean']>;
  progressInSeconds?: Maybe<Scalars['Float']>;
  purpose: TopicPurpose;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  topicDescription?: Maybe<Scalars['String']>;
  topicId?: Maybe<Scalars['ID']>;
  topicName?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  url: Scalars['String'];
  viewStatus?: Maybe<ViewStatus>;
};

export type UserContentItems = {
  __typename?: 'UserContentItems';
  content: Array<UserContentItem>;
};

export type UserInput = {
  ageRange?: InputMaybe<AgeRange>;
  contentPreferences?: InputMaybe<Array<ContentPreferences>>;
  gender?: InputMaybe<Gender>;
  interestedInExperts?: InputMaybe<Scalars['Boolean']>;
  lenghtOfVideos?: InputMaybe<LenghtOfVideos>;
  toneOfVoice?: InputMaybe<ToneOfVoice>;
};

export type UserOutput = {
  __typename?: 'UserOutput';
  ageRange?: Maybe<AgeRange>;
  appleUserId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contentPreferences?: Maybe<Array<ContentPreferences>>;
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Gender>;
  hasFilledQuestionnaire: Scalars['Boolean'];
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  lenghtOfVideos?: Maybe<LenghtOfVideos>;
  profilePhoto?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  toneOfVoice?: Maybe<ToneOfVoice>;
};

export type UserWithTokenType = {
  __typename?: 'UserWithTokenType';
  token: Scalars['String'];
  user: UserOutput;
};

export enum ViewStatus {
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
  Viewed = 'VIEWED'
}

export type CreateTextLessonMutationVariables = Exact<{
  title: Scalars['String'];
  pages: Array<CreateTextLessonPageArgs> | CreateTextLessonPageArgs;
  thumbnailUrl: Scalars['String'];
  duration: Scalars['Float'];
}>;


export type CreateTextLessonMutation = { __typename?: 'Mutation', createTextLesson: { __typename?: 'SuccessPayload', success: boolean } };

export type CreateTopicMutationVariables = Exact<{
  title: Scalars['String'];
  description: Scalars['String'];
  thumbnailUrl: Scalars['String'];
}>;


export type CreateTopicMutation = { __typename?: 'Mutation', createTopic: { __typename?: 'SuccessPayload', success: boolean } };

export type CurrentUserQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQueryQuery = { __typename?: 'Query', currentUser: { __typename?: 'UserOutput', id: string, firstName: string, email: string, profilePhoto?: string | null, hasFilledQuestionnaire: boolean, role?: Role | null } };

export type GetAllContentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllContentQuery = { __typename?: 'Query', getAllContent: Array<{ __typename?: 'ContentType', id: string, title: string, description: string, url: string, thumbnailUrl: string }> };

export type GetContentQueryVariables = Exact<{
  contentId: Scalars['String'];
}>;


export type GetContentQuery = { __typename?: 'Query', getContent: { __typename?: 'ContentType', id: string, title: string, description: string, url: string, thumbnailUrl: string, topicId?: string | null, topicName?: string | null, applicableAges?: Array<string> | null, applicableGenders?: Array<string> | null, applicablePreferences?: Array<string> | null } };

export type GetAllTopicsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTopicsQuery = { __typename?: 'Query', getAllTopics: Array<{ __typename?: 'AllTopicsType', id: string, title: string, description: string, thumbnailUrl: string }> };

export type LoginUserMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  appleUserId?: InputMaybe<Scalars['String']>;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser: { __typename?: 'UserWithTokenType', token: string, user: { __typename?: 'UserOutput', id: string, firstName: string } } };

export type GetSignedUrlMutationVariables = Exact<{
  audioFileName?: InputMaybe<Scalars['String']>;
  photoFileName?: InputMaybe<Scalars['String']>;
}>;


export type GetSignedUrlMutation = { __typename?: 'Mutation', getSignedUrl: { __typename?: 'SignedUrl', audioUrl?: string | null, photoUrl?: string | null } };

export type UpdateContentMutationVariables = Exact<{
  contentId: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
}>;


export type UpdateContentMutation = { __typename?: 'Mutation', updateContent: { __typename?: 'SuccessPayload', success: boolean } };

export type UpdateTopicMutationVariables = Exact<{
  topicId: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
}>;


export type UpdateTopicMutation = { __typename?: 'Mutation', updateTopic: { __typename?: 'SuccessPayload', success: boolean } };

export type UploadContentMutationVariables = Exact<{
  title: Scalars['String'];
  mediaType: Scalars['String'];
  description: Scalars['String'];
  audioUrl: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  topicName?: InputMaybe<Scalars['String']>;
  applicablePreferences?: InputMaybe<Array<ContentPreferences> | ContentPreferences>;
}>;


export type UploadContentMutation = { __typename?: 'Mutation', uploadContent: { __typename?: 'SuccessPayload', success: boolean } };


export const CreateTextLessonDocument = gql`
    mutation CreateTextLesson($title: String!, $pages: [CreateTextLessonPageArgs!]!, $thumbnailUrl: String!, $duration: Float!) {
  createTextLesson(
    title: $title
    pages: $pages
    thumbnailUrl: $thumbnailUrl
    duration: $duration
  ) {
    success
  }
}
    `;
export type CreateTextLessonMutationFn = Apollo.MutationFunction<CreateTextLessonMutation, CreateTextLessonMutationVariables>;

/**
 * __useCreateTextLessonMutation__
 *
 * To run a mutation, you first call `useCreateTextLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTextLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTextLessonMutation, { data, loading, error }] = useCreateTextLessonMutation({
 *   variables: {
 *      title: // value for 'title'
 *      pages: // value for 'pages'
 *      thumbnailUrl: // value for 'thumbnailUrl'
 *      duration: // value for 'duration'
 *   },
 * });
 */
export function useCreateTextLessonMutation(baseOptions?: Apollo.MutationHookOptions<CreateTextLessonMutation, CreateTextLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTextLessonMutation, CreateTextLessonMutationVariables>(CreateTextLessonDocument, options);
      }
export type CreateTextLessonMutationHookResult = ReturnType<typeof useCreateTextLessonMutation>;
export type CreateTextLessonMutationResult = Apollo.MutationResult<CreateTextLessonMutation>;
export type CreateTextLessonMutationOptions = Apollo.BaseMutationOptions<CreateTextLessonMutation, CreateTextLessonMutationVariables>;
export const CreateTopicDocument = gql`
    mutation CreateTopic($title: String!, $description: String!, $thumbnailUrl: String!) {
  createTopic(
    title: $title
    description: $description
    thumbnailUrl: $thumbnailUrl
  ) {
    success
  }
}
    `;
export type CreateTopicMutationFn = Apollo.MutationFunction<CreateTopicMutation, CreateTopicMutationVariables>;

/**
 * __useCreateTopicMutation__
 *
 * To run a mutation, you first call `useCreateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTopicMutation, { data, loading, error }] = useCreateTopicMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      thumbnailUrl: // value for 'thumbnailUrl'
 *   },
 * });
 */
export function useCreateTopicMutation(baseOptions?: Apollo.MutationHookOptions<CreateTopicMutation, CreateTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTopicMutation, CreateTopicMutationVariables>(CreateTopicDocument, options);
      }
export type CreateTopicMutationHookResult = ReturnType<typeof useCreateTopicMutation>;
export type CreateTopicMutationResult = Apollo.MutationResult<CreateTopicMutation>;
export type CreateTopicMutationOptions = Apollo.BaseMutationOptions<CreateTopicMutation, CreateTopicMutationVariables>;
export const CurrentUserQueryDocument = gql`
    query CurrentUserQuery {
  currentUser {
    id
    firstName
    email
    profilePhoto
    hasFilledQuestionnaire
    role
  }
}
    `;

/**
 * __useCurrentUserQueryQuery__
 *
 * To run a query within a React component, call `useCurrentUserQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQueryQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQueryQuery, CurrentUserQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQueryQuery, CurrentUserQueryQueryVariables>(CurrentUserQueryDocument, options);
      }
export function useCurrentUserQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQueryQuery, CurrentUserQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQueryQuery, CurrentUserQueryQueryVariables>(CurrentUserQueryDocument, options);
        }
export type CurrentUserQueryQueryHookResult = ReturnType<typeof useCurrentUserQueryQuery>;
export type CurrentUserQueryLazyQueryHookResult = ReturnType<typeof useCurrentUserQueryLazyQuery>;
export type CurrentUserQueryQueryResult = Apollo.QueryResult<CurrentUserQueryQuery, CurrentUserQueryQueryVariables>;
export const GetAllContentDocument = gql`
    query GetAllContent {
  getAllContent {
    id
    title
    description
    url
    thumbnailUrl
  }
}
    `;

/**
 * __useGetAllContentQuery__
 *
 * To run a query within a React component, call `useGetAllContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllContentQuery(baseOptions?: Apollo.QueryHookOptions<GetAllContentQuery, GetAllContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllContentQuery, GetAllContentQueryVariables>(GetAllContentDocument, options);
      }
export function useGetAllContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllContentQuery, GetAllContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllContentQuery, GetAllContentQueryVariables>(GetAllContentDocument, options);
        }
export type GetAllContentQueryHookResult = ReturnType<typeof useGetAllContentQuery>;
export type GetAllContentLazyQueryHookResult = ReturnType<typeof useGetAllContentLazyQuery>;
export type GetAllContentQueryResult = Apollo.QueryResult<GetAllContentQuery, GetAllContentQueryVariables>;
export const GetContentDocument = gql`
    query GetContent($contentId: String!) {
  getContent(contentId: $contentId) {
    id
    title
    description
    url
    thumbnailUrl
    topicId
    topicName
    applicableAges
    applicableGenders
    applicablePreferences
  }
}
    `;

/**
 * __useGetContentQuery__
 *
 * To run a query within a React component, call `useGetContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentQuery({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useGetContentQuery(baseOptions: Apollo.QueryHookOptions<GetContentQuery, GetContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentQuery, GetContentQueryVariables>(GetContentDocument, options);
      }
export function useGetContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentQuery, GetContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentQuery, GetContentQueryVariables>(GetContentDocument, options);
        }
export type GetContentQueryHookResult = ReturnType<typeof useGetContentQuery>;
export type GetContentLazyQueryHookResult = ReturnType<typeof useGetContentLazyQuery>;
export type GetContentQueryResult = Apollo.QueryResult<GetContentQuery, GetContentQueryVariables>;
export const GetAllTopicsDocument = gql`
    query GetAllTopics {
  getAllTopics {
    id
    title
    description
    thumbnailUrl
  }
}
    `;

/**
 * __useGetAllTopicsQuery__
 *
 * To run a query within a React component, call `useGetAllTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTopicsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTopicsQuery, GetAllTopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTopicsQuery, GetAllTopicsQueryVariables>(GetAllTopicsDocument, options);
      }
export function useGetAllTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTopicsQuery, GetAllTopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTopicsQuery, GetAllTopicsQueryVariables>(GetAllTopicsDocument, options);
        }
export type GetAllTopicsQueryHookResult = ReturnType<typeof useGetAllTopicsQuery>;
export type GetAllTopicsLazyQueryHookResult = ReturnType<typeof useGetAllTopicsLazyQuery>;
export type GetAllTopicsQueryResult = Apollo.QueryResult<GetAllTopicsQuery, GetAllTopicsQueryVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($email: String, $password: String, $appleUserId: String) {
  loginUser(email: $email, password: $password, appleUserId: $appleUserId) {
    token
    user {
      id
      firstName
    }
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      appleUserId: // value for 'appleUserId'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const GetSignedUrlDocument = gql`
    mutation GetSignedUrl($audioFileName: String, $photoFileName: String) {
  getSignedUrl(audioFileName: $audioFileName, photoFileName: $photoFileName) {
    audioUrl
    photoUrl
  }
}
    `;
export type GetSignedUrlMutationFn = Apollo.MutationFunction<GetSignedUrlMutation, GetSignedUrlMutationVariables>;

/**
 * __useGetSignedUrlMutation__
 *
 * To run a mutation, you first call `useGetSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSignedUrlMutation, { data, loading, error }] = useGetSignedUrlMutation({
 *   variables: {
 *      audioFileName: // value for 'audioFileName'
 *      photoFileName: // value for 'photoFileName'
 *   },
 * });
 */
export function useGetSignedUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetSignedUrlMutation, GetSignedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSignedUrlMutation, GetSignedUrlMutationVariables>(GetSignedUrlDocument, options);
      }
export type GetSignedUrlMutationHookResult = ReturnType<typeof useGetSignedUrlMutation>;
export type GetSignedUrlMutationResult = Apollo.MutationResult<GetSignedUrlMutation>;
export type GetSignedUrlMutationOptions = Apollo.BaseMutationOptions<GetSignedUrlMutation, GetSignedUrlMutationVariables>;
export const UpdateContentDocument = gql`
    mutation UpdateContent($contentId: String!, $title: String, $description: String, $thumbnailUrl: String) {
  updateContent(
    contentId: $contentId
    title: $title
    description: $description
    thumbnailUrl: $thumbnailUrl
  ) {
    success
  }
}
    `;
export type UpdateContentMutationFn = Apollo.MutationFunction<UpdateContentMutation, UpdateContentMutationVariables>;

/**
 * __useUpdateContentMutation__
 *
 * To run a mutation, you first call `useUpdateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentMutation, { data, loading, error }] = useUpdateContentMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      thumbnailUrl: // value for 'thumbnailUrl'
 *   },
 * });
 */
export function useUpdateContentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContentMutation, UpdateContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContentMutation, UpdateContentMutationVariables>(UpdateContentDocument, options);
      }
export type UpdateContentMutationHookResult = ReturnType<typeof useUpdateContentMutation>;
export type UpdateContentMutationResult = Apollo.MutationResult<UpdateContentMutation>;
export type UpdateContentMutationOptions = Apollo.BaseMutationOptions<UpdateContentMutation, UpdateContentMutationVariables>;
export const UpdateTopicDocument = gql`
    mutation UpdateTopic($topicId: String!, $title: String, $description: String, $thumbnailUrl: String) {
  updateTopic(
    topicId: $topicId
    title: $title
    description: $description
    thumbnailUrl: $thumbnailUrl
  ) {
    success
  }
}
    `;
export type UpdateTopicMutationFn = Apollo.MutationFunction<UpdateTopicMutation, UpdateTopicMutationVariables>;

/**
 * __useUpdateTopicMutation__
 *
 * To run a mutation, you first call `useUpdateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicMutation, { data, loading, error }] = useUpdateTopicMutation({
 *   variables: {
 *      topicId: // value for 'topicId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      thumbnailUrl: // value for 'thumbnailUrl'
 *   },
 * });
 */
export function useUpdateTopicMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopicMutation, UpdateTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopicMutation, UpdateTopicMutationVariables>(UpdateTopicDocument, options);
      }
export type UpdateTopicMutationHookResult = ReturnType<typeof useUpdateTopicMutation>;
export type UpdateTopicMutationResult = Apollo.MutationResult<UpdateTopicMutation>;
export type UpdateTopicMutationOptions = Apollo.BaseMutationOptions<UpdateTopicMutation, UpdateTopicMutationVariables>;
export const UploadContentDocument = gql`
    mutation UploadContent($title: String!, $mediaType: String!, $description: String!, $audioUrl: String!, $thumbnailUrl: String!, $topicName: String, $applicablePreferences: [ContentPreferences!]) {
  uploadContent(
    title: $title
    mediaType: $mediaType
    description: $description
    audioUrl: $audioUrl
    thumbnailUrl: $thumbnailUrl
    topicName: $topicName
    applicablePreferences: $applicablePreferences
  ) {
    success
  }
}
    `;
export type UploadContentMutationFn = Apollo.MutationFunction<UploadContentMutation, UploadContentMutationVariables>;

/**
 * __useUploadContentMutation__
 *
 * To run a mutation, you first call `useUploadContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadContentMutation, { data, loading, error }] = useUploadContentMutation({
 *   variables: {
 *      title: // value for 'title'
 *      mediaType: // value for 'mediaType'
 *      description: // value for 'description'
 *      audioUrl: // value for 'audioUrl'
 *      thumbnailUrl: // value for 'thumbnailUrl'
 *      topicName: // value for 'topicName'
 *      applicablePreferences: // value for 'applicablePreferences'
 *   },
 * });
 */
export function useUploadContentMutation(baseOptions?: Apollo.MutationHookOptions<UploadContentMutation, UploadContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadContentMutation, UploadContentMutationVariables>(UploadContentDocument, options);
      }
export type UploadContentMutationHookResult = ReturnType<typeof useUploadContentMutation>;
export type UploadContentMutationResult = Apollo.MutationResult<UploadContentMutation>;
export type UploadContentMutationOptions = Apollo.BaseMutationOptions<UploadContentMutation, UploadContentMutationVariables>;