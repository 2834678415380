import * as Yup from "yup";
import {
  useCreateTextLessonMutation,
  useGetSignedUrlMutation,
} from "../../graphql/generated/graphql";
import { useFormik } from "formik";
import { useState } from "react";
import { CreateTextLessonPageForm } from "./CreateTextLessonPageForm";
import { v4 as uuidv4 } from "uuid";

const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  duration: Yup.number().required("Estimated reading duration is required"),
});

const CreateTextContent = () => {
  const [getSignedUrl] = useGetSignedUrlMutation();
  const [createTextLesson] = useCreateTextLessonMutation();
  const [textPages, setTextPages] = useState<
    { content: string; imageUrl: string | null }[]
  >([]);
  const [showPageForm, setShowPageForm] = useState(false);

  const handleSubmit = async (values: any) => {
    try {
      const uuid = uuidv4();
      const photoFileName = `thumbnail_${uuid}.jpg`;
      const { data } = await getSignedUrl({
        variables: {
          photoFileName: photoFileName,
        },
      });
      const imageUrl = data?.getSignedUrl.photoUrl;
      if (!imageUrl) {
        throw new Error("Failed to get signed URL");
      }
      await fetch(imageUrl, {
        method: "PUT",
        body: values.photo,
        headers: {
          "Content-Type": "image/jpeg",
        },
      });
      const uploadedImageUrl = `https://ilonaphotos.s3.eu-west-2.amazonaws.com/${photoFileName}`;
      createTextLesson({
        variables: {
          title: values.title,
          duration: values.duration,
          thumbnailUrl: uploadedImageUrl,
          pages: textPages,
        },
      });
      formik.resetForm();
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      photo: null as File | null,
      duration: 0,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const addPage = (page: { content: string; imageUrl: string | null }) => {
    setTextPages([...textPages, page]);
    setShowPageForm(false);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-6 p-5">
      <div className="flex items-center space-x-4">
        <label className="flex-none w-1/4 text-right">Title</label>
        <div className="flex-1">
          <input
            type="text"
            placeholder="Title"
            {...formik.getFieldProps("title")}
            className="input input-bordered w-full border p-2"
          />
          {formik.touched.title && formik.errors.title && (
            <div className="text-red-500 text-xs mt-1">
              {formik.errors.title}
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <label className="flex-none w-1/4 text-right">Photo</label>
        <input
          type="file"
          onChange={(event) =>
            formik.setFieldValue(
              "photo",
              event.currentTarget.files ? event.currentTarget.files[0] : null
            )
          }
          className="input input-bordered flex-1 border p-2"
        />
      </div>
      <div className="flex items-center space-x-4">
        <label className="flex-none w-1/4 text-right">Duration</label>
        <div className="flex-1">
          <input
            type="number"
            placeholder="Estimated duration in minutes"
            {...formik.getFieldProps("duration")}
            className="input input-bordered w-full border p-2"
          />
          {formik.touched.duration && formik.errors.duration && (
            <div className="text-red-500 text-xs mt-1">
              {formik.errors.duration}
            </div>
          )}
        </div>
      </div>
      <button
        type="button"
        className="btn btn-secondary"
        onClick={() => setShowPageForm(true)}
      >
        Add Page
      </button>
      {showPageForm && <CreateTextLessonPageForm onSubmit={addPage} />}
      <ul>
        {textPages.map((page, index) => (
          <li key={index}>{page.content} - Image attached</li>
        ))}
      </ul>
      <button type="submit" className="btn btn-primary block ml-auto">
        Upload
      </button>
    </form>
  );
};

export default CreateTextContent;
