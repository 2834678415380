import React from "react";
import { Link } from "react-router-dom"; // Assuming you are using React Router for navigation

const Dashboard: React.FC = () => {
  return (
    <div className="p-10 grid grid-cols-2 gap-4">
      <Link
        to="/thrivy/create-content"
        className="btn btn-lg bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Create Content
      </Link>
      <Link
        to="/thrivy/content"
        className="btn btn-lg bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        View Content
      </Link>
      <Link
        to="/thrivy/create-topic"
        className="btn btn-lg bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Create Topic
      </Link>
      <Link
        to="/thrivy/topics"
        className="btn btn-lg bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        View Topics
      </Link>
      <Link
        to="/thrivy/create-theory"
        className="btn btn-lg bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Create Theory
      </Link>
      <Link
        to="/thrivy/theory"
        className="btn btn-lg bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        View Theory
      </Link>
    </div>
  );
};

export default Dashboard;
