import { ChangeEvent, FC, useEffect, useRef } from "react";
import {
  ContentType,
  useGetAllContentQuery,
  useGetSignedUrlMutation,
  useUpdateContentMutation,
} from "../../graphql/generated/graphql";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";

interface EditableContent extends ContentType {
  isEditing: boolean;
}

const Content: FC = () => {
  const { data: contentData, refetch } = useGetAllContentQuery();
  const [content, setContent] = useState<EditableContent[]>([]);
  const [updateContent] = useUpdateContentMutation();
  const [getSignedUrl] = useGetSignedUrlMutation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleEditToggle = (id: string) => {
    const updatedContent = content.map((practice) => {
      if (practice.id === id) {
        if (!practice.isEditing) {
          return { ...practice, isEditing: true };
        } else {
          updateContent({ variables: { contentId: id, ...practice } });
          refetch();
          return { ...practice, isEditing: false };
        }
      }
      return practice;
    });
    setContent(updatedContent);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string,
    key: string
  ) => {
    const value = event.target.value;
    const updatedContent = content.map((practice) => {
      if (practice.id === id) {
        return { ...practice, [key]: value };
      }
      return practice;
    });
    setContent(updatedContent);
  };

  const handleImageClick = (id: string) => {
    fileInputRef.current?.click();
    fileInputRef.current?.addEventListener("change", (event) => {
      if (fileInputRef.current?.files?.length) {
        const file = fileInputRef.current.files[0];

        uploadImage(file, id);
      }
    });
  };

  const uploadImage = async (file: File, id: string) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const updatedContent = content.map((practice) => {
        if (practice.id === id) {
          return { ...practice, thumbnailUrl: reader.result as string };
        }
        return practice;
      });
      setContent(updatedContent);
    };
    try {
      const uuid = uuidv4();
      const thumbnailFilename = `thumbnail_${uuid}.jpg`;
      const { data } = await getSignedUrl({
        variables: {
          photoFileName: thumbnailFilename,
        },
      });

      if (!data?.getSignedUrl?.photoUrl) {
        throw new Error("Error getting signed URLs");
      }

      const { photoUrl } = data.getSignedUrl;

      try {
        await Promise.all([
          fetch(photoUrl, {
            method: "PUT",
            body: file,
            headers: {
              "Content-Type": "image/jpeg",
            },
          }),
        ]);
        const uploadedThumbnailUrl = `https://ilonaphotos.s3.eu-west-2.amazonaws.com/${thumbnailFilename}`;

        await updateContent({
          variables: {
            contentId: id,
            thumbnailUrl: uploadedThumbnailUrl,
          },
        });
        refetch();
        alert("Topic created successfully!");
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  useEffect(() => {
    if (contentData) {
      const topicsWithEdit = contentData.getAllContent.map((topic) => ({
        ...topic,
        isEditing: false,
      }));
      setContent(topicsWithEdit as EditableContent[]);
    }
  }, [contentData]);

  return (
    <div className="max-w-7xl mx-auto p-4 grid grid-cols-3 gap-4">
      {content.map((topic) => (
        <div key={topic.id} className="border p-3 rounded shadow">
          <input
            type="text"
            value={topic.title}
            onChange={(e) => handleChange(e, topic.id, "title")}
            className="w-full mb-2 p-2 border rounded"
            disabled={!topic.isEditing}
          />
          <textarea
            value={topic.description}
            onChange={(e) => handleChange(e, topic.id, "description")}
            className="w-full h-24 p-2 border rounded mb-2"
            disabled={!topic.isEditing}
          />
          <img
            src={topic.thumbnailUrl || "default-thumbnail.png"}
            alt="Thumbnail"
            className="w-full max-h-32 object-cover rounded mb-2 cursor-pointer"
            onClick={() => handleImageClick(topic.id)}
          />
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            accept="image/*"
          />
          <button
            onClick={() => handleEditToggle(topic.id)}
            className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 float-right"
          >
            {topic.isEditing ? "Save" : "Edit"}
          </button>
        </div>
      ))}
    </div>
  );
};

export default Content;
