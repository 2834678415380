import React from "react";
import { useFormik } from "formik";
import {
  AgeRange,
  ContentPreferences,
  Gender,
  useGetAllTopicsQuery,
  useGetSignedUrlMutation,
  useUploadContentMutation,
} from "../../graphql/generated/graphql";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  topicName: Yup.string().required("Topic name is required"),
  ageRanges: Yup.array().min(1, "At least one age range must be selected"),
  genders: Yup.array().min(1, "At least one gender must be selected"),
});

const UploadContent: React.FC = () => {
  const [getSignedUrl] = useGetSignedUrlMutation();
  const [uploadContent] = useUploadContentMutation();
  const { data: topicsData } = useGetAllTopicsQuery();

  const formik = useFormik({
    initialValues: {
      title: "",
      mediaType: "",
      description: "",
      audio: null as File | null,
      photo: null as File | null,
      topicName: "",
      ageRanges: [
        AgeRange.Range18_24,
        AgeRange.Range25_34,
        AgeRange.Range35_44,
        AgeRange.Range45_54,
        AgeRange.Range55_64,
        AgeRange.Range65Plus,
      ],
      genders: [Gender.Female, Gender.Male, Gender.Other],
      applicablePreferences: Object.values(ContentPreferences),
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Handle file upload then submit content metadata
      if (values.audio && values.photo) {
        try {
          const uuid = uuidv4();
          const audioFileName = `${uuid}_${values.audio.name}`;
          const thumbnailFilename = `thumbnail_${uuid}.jpg`;
          const { data } = await getSignedUrl({
            variables: {
              audioFileName: audioFileName,
              photoFileName: thumbnailFilename,
            },
          });

          if (!data) {
            throw new Error("Error getting signed URLs");
          }

          const { audioUrl, photoUrl } = data.getSignedUrl;

          if (!audioUrl || !photoUrl) {
            throw new Error("Error getting signed URLs");
          }
          await Promise.all([
            fetch(audioUrl, {
              method: "PUT",
              body: values.audio,
              headers: {
                "Content-Type": "audio/mpeg",
              },
            }),
            fetch(photoUrl, {
              method: "PUT",
              body: values.photo,
              headers: {
                "Content-Type": "image/jpeg",
              },
            }),
          ]);

          const uploadedAudioUrl = `https://ilonaphotos.s3.eu-west-2.amazonaws.com/${audioFileName}`;
          const uploadedThumbnailUrl = `https://ilonaphotos.s3.eu-west-2.amazonaws.com/${thumbnailFilename}`;

          await uploadContent({
            variables: {
              title: values.title,
              mediaType: "audio/mpeg",
              description: values.description,
              audioUrl: uploadedAudioUrl,
              thumbnailUrl: uploadedThumbnailUrl,
              topicName: values.topicName,
              applicablePreferences: values.applicablePreferences,
            },
          });

          alert("Content uploaded successfully!");
        } catch (error) {
          console.error("Error uploading files:", error);
        }
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-6 p-5">
      <div className="flex items-center space-x-4">
        <label className="flex-none w-1/4 text-right">Title</label>
        <div className="flex-1">
          <input
            type="text"
            placeholder="Title"
            {...formik.getFieldProps("title")}
            className="input input-bordered w-full border p-2"
          />
          {formik.touched.title && formik.errors.title && (
            <div className="text-red-500 text-xs mt-1">
              {formik.errors.title}
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <label className="flex-none w-1/4 text-right">Description</label>
        <div className="flex-1">
          <textarea
            {...formik.getFieldProps("description")}
            className="textarea textarea-bordered w-full border p-2"
            placeholder="Description"
          ></textarea>
          {formik.touched.description && formik.errors.description && (
            <div className="text-red-500 text-xs mt-1">
              {formik.errors.description}
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <label className="flex-none w-1/4 text-right">Audio File</label>
        <input
          type="file"
          onChange={(event) =>
            formik.setFieldValue(
              "audio",
              event.currentTarget.files ? event.currentTarget.files[0] : null
            )
          }
          className="input input-bordered flex-1 border p-2"
        />
      </div>

      <div className="flex items-center space-x-4">
        <label className="flex-none w-1/4 text-right">Photo</label>
        <input
          type="file"
          onChange={(event) =>
            formik.setFieldValue(
              "photo",
              event.currentTarget.files ? event.currentTarget.files[0] : null
            )
          }
          className="input input-bordered flex-1 border p-2"
        />
      </div>

      <div className="flex items-center space-x-4">
        <label className="flex-none w-1/4 text-right">Topic Name</label>
        <div className="flex-1">
          <select
            {...formik.getFieldProps("topicName")}
            className="select select-bordered w-full"
          >
            <option value="">Select a topic</option>
            {topicsData?.getAllTopics.map((topic) => (
              <option key={topic.id} value={topic.id}>
                {topic.title}
              </option>
            ))}
          </select>
          {formik.touched.topicName && formik.errors.topicName && (
            <div className="text-red-500 text-xs mt-1">
              {formik.errors.topicName}
            </div>
          )}
        </div>
      </div>

      <fieldset className="flex items-center space-x-4">
        <label className="flex-none w-1/4 text-right">Age Ranges</label>
        <div className="flex-1 space-y-2">
          {Object.values(AgeRange).map((age) => (
            <div key={age} className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={formik.values.ageRanges.includes(age)}
                onChange={() => {
                  const set = new Set(formik.values.ageRanges);
                  if (set.has(age)) {
                    set.delete(age);
                  } else {
                    set.add(age);
                  }
                  formik.setFieldValue("ageRanges", Array.from(set));
                }}
                className="checkbox"
              />
              <span>{age}</span>
            </div>
          ))}
          {formik.touched.ageRanges && formik.errors.ageRanges && (
            <div className="text-red-500 text-xs mt-1">
              {formik.errors.ageRanges}
            </div>
          )}
        </div>
      </fieldset>

      <fieldset className="flex items-center space-x-4">
        <label className="flex-none w-1/4 text-right">Genders</label>
        <div className="flex-1 space-y-2">
          {Object.values(Gender).map((gender) => (
            <div key={gender} className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={formik.values.genders.includes(gender)}
                onChange={() => {
                  const set = new Set(formik.values.genders);
                  if (set.has(gender)) {
                    set.delete(gender);
                  } else {
                    set.add(gender);
                  }
                  formik.setFieldValue("genders", Array.from(set));
                }}
                className="checkbox"
              />
              <span>{gender}</span>
            </div>
          ))}
          {formik.touched.genders && formik.errors.genders && (
            <div className="text-red-500 text-xs mt-1">
              {formik.errors.genders}
            </div>
          )}
        </div>
      </fieldset>

      <fieldset className="flex items-center space-x-4">
        <label className="flex-none w-1/4 text-right">Preferences</label>
        <div className="flex-1 space-y-2">
          {Object.values(ContentPreferences).map((preference) => (
            <div key={preference} className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={formik.values.applicablePreferences.includes(
                  preference
                )}
                onChange={() => {
                  const set = new Set(formik.values.applicablePreferences);
                  if (set.has(preference)) {
                    set.delete(preference);
                  } else {
                    set.add(preference);
                  }
                  formik.setFieldValue(
                    "applicablePreferences",
                    Array.from(set)
                  );
                }}
                className="checkbox"
              />
              <span>{preference}</span>
            </div>
          ))}
          {formik.touched.genders && formik.errors.genders && (
            <div className="text-red-500 text-xs mt-1">
              {formik.errors.genders}
            </div>
          )}
        </div>
      </fieldset>

      <button type="submit" className="btn btn-primary block ml-auto">
        Upload
      </button>
    </form>
  );
};

export default UploadContent;
