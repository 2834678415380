import React from "react";
import { useFormik } from "formik";
import {
  useCreateTopicMutation,
  useGetSignedUrlMutation,
} from "../../graphql/generated/graphql";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
});

const CreateTopic: React.FC = () => {
  const [getSignedUrl] = useGetSignedUrlMutation();
  const [createTopic] = useCreateTopicMutation();

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      photo: null as File | null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (values.photo) {
        try {
          const uuid = uuidv4();
          const thumbnailFilename = `thumbnail_${uuid}.jpg`;
          const { data } = await getSignedUrl({
            variables: {
              photoFileName: thumbnailFilename,
            },
          });

          if (!data?.getSignedUrl?.photoUrl) {
            throw new Error("Error getting signed URLs");
          }

          const { photoUrl } = data.getSignedUrl;

          try {
            await Promise.all([
              fetch(photoUrl, {
                method: "PUT",
                body: values.photo,
                headers: {
                  "Content-Type": "image/jpeg",
                },
              }),
            ]);
            const uploadedThumbnailUrl = `https://ilonaphotos.s3.eu-west-2.amazonaws.com/${thumbnailFilename}`;

            await createTopic({
              variables: {
                title: values.title,
                description: values.description,
                thumbnailUrl: uploadedThumbnailUrl,
              },
            });

            alert("Topic created successfully!");
          } catch (error) {
            console.error("Error uploading files:", error);
          }
        } catch (error) {
          console.error("Error uploading files:", error);
        }
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-6 p-5">
      <div className="flex items-center space-x-4">
        <label className="flex-none w-1/4 text-right">Title</label>
        <div className="flex-1">
          <input
            type="text"
            placeholder="Title"
            {...formik.getFieldProps("title")}
            className="input input-bordered w-full border p-2"
          />
          {formik.touched.title && formik.errors.title && (
            <div className="text-red-500 text-xs mt-1">
              {formik.errors.title}
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <label className="flex-none w-1/4 text-right">Description</label>
        <div className="flex-1">
          <textarea
            {...formik.getFieldProps("description")}
            className="textarea textarea-bordered w-full border p-2"
            placeholder="Description"
          ></textarea>
          {formik.touched.description && formik.errors.description && (
            <div className="text-red-500 text-xs mt-1">
              {formik.errors.description}
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <label className="flex-none w-1/4 text-right">Photo</label>
        <input
          type="file"
          onChange={(event) =>
            formik.setFieldValue(
              "photo",
              event.currentTarget.files ? event.currentTarget.files[0] : null
            )
          }
          className="input input-bordered flex-1 border p-2"
        />
      </div>
      <button type="submit" className="btn btn-primary block ml-auto">
        Upload
      </button>
    </form>
  );
};

export default CreateTopic;
