import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import HomeScreen from "./Screens/Home";
import NotFound from "./Screens/NotFound";
import { ApolloProvider } from "@apollo/client";
import { AuthProvider } from "./context/AuthProvider";
import Layout from "./components/Layout";
import ProtectedRoute from "./components/ProtectedRoute";
import apolloClient from "./lib/appoloClient";
import Login from "./Screens/Home/Login";
import UploadContent from "./Screens/UploadContent";
import Dashboard from "./Screens/Dashboard";
import CreateTopic from "./Screens/CreateTopic";
import Topics from "./Screens/Topics";
import Content from "./Screens/Content";
import CreateTextContent from "./Screens/CreateTextContent";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ApolloProvider client={apolloClient}>
        <Router>
          <Layout>
            <div className="App">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <HomeScreen />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/thrivy"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/thrivy/create-content"
                  element={
                    <ProtectedRoute>
                      <UploadContent />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/thrivy/create-topic"
                  element={
                    <ProtectedRoute>
                      <CreateTopic />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/thrivy/topics"
                  element={
                    <ProtectedRoute>
                      <Topics />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/thrivy/content"
                  element={
                    <ProtectedRoute>
                      <Content />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/thrivy/theory"
                  element={
                    <ProtectedRoute>
                      <Content />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/thrivy/create-theory"
                  element={
                    <ProtectedRoute>
                      <CreateTextContent />
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </Layout>
        </Router>
      </ApolloProvider>
    </AuthProvider>
  );
};

export default App;
