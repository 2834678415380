import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useGetSignedUrlMutation } from "../../graphql/generated/graphql";

const pageValidationSchema = Yup.object({
  content: Yup.string().required("Page title is required"),
});

interface PageFormProps {
  onSubmit: (values: { content: string; imageUrl: string | null }) => void;
}

export const CreateTextLessonPageForm: React.FC<PageFormProps> = ({
  onSubmit,
}) => {
  const [getSignedUrl] = useGetSignedUrlMutation();
  const handleSubmit = async () => {
    const values = formik.values;
    try {
      const uuid = uuidv4();
      const photoFileName = `photo_${uuid}.jpg`;
      const { data } = await getSignedUrl({
        variables: {
          photoFileName: photoFileName,
        },
      });
      const imageUrl = data?.getSignedUrl.photoUrl;
      if (!imageUrl) {
        throw new Error("Failed to get signed URL");
      }
      await fetch(imageUrl, {
        method: "PUT",
        body: values.photo,
        headers: {
          "Content-Type": "image/jpeg",
        },
      });
      const uploadedImageUrl = `https://ilonaphotos.s3.eu-west-2.amazonaws.com/${photoFileName}`;

      onSubmit({ content: values.content, imageUrl: uploadedImageUrl });
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      content: "",
      photo: null as File | null,
    },
    validationSchema: pageValidationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-4">
        <label className="flex-none w-1/4 text-right">Text content</label>
        <div className="flex-1">
          <textarea
            placeholder="Text content"
            {...formik.getFieldProps("content")}
            className="input input-bordered w-full border p-2"
            style={{ minHeight: "150px" }}
          />
          {formik.touched.content && formik.errors.content && (
            <div className="text-red-500 text-xs mt-1">
              {formik.errors.content}
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <label className="flex-none w-1/4 text-right">Image</label>
        <input
          type="file"
          onChange={(event) =>
            formik.setFieldValue(
              "photo",
              event.currentTarget.files ? event.currentTarget.files[0] : null
            )
          }
          className="input input-bordered flex-1 border p-2"
        />
      </div>
      <button
        type="submit"
        onClick={() => formik.handleSubmit()}
        className="btn btn-primary"
      >
        Submit page
      </button>
    </div>
  );
};
