import React from "react";
import NavBar from "./NavBar";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <NavBar />
      <main className="flex-grow container mx-auto py-8 px-4 mt-16">
        {children}
      </main>
      <footer className="w-full bg-gray-200 p-2 z-50 text-center">
        <p>© {new Date().getFullYear()} ZenithTech LTD. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Layout;
