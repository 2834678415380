import React, { createContext, useContext, useEffect, useState } from "react";
import { getCookie } from "../utils/storage";

interface AuthContextValue {
  token?: string;
  setToken: (token: string | undefined) => void;
  userId?: string;
  setUserId: (userId: string | undefined) => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | undefined>(getCookie("jwtToken"));
  const [userId, setUserId] = useState<string | undefined>(getCookie("userId"));

  useEffect(() => {
    // This effect will run once on component mount and whenever the cookie changes
    const jwtToken = getCookie("jwtToken");
    const userIdFromCookie = getCookie("userId");

    if (jwtToken !== token) {
      setToken(jwtToken);
    }
    if (userIdFromCookie !== userId) {
      setUserId(userIdFromCookie);
    }
  }, [token, userId, setToken, setUserId]);

  return (
    <AuthContext.Provider value={{ token, setToken, userId, setUserId }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
